// Core
import { ReactNode } from "react";

// Components
import { Row } from "@/client/components/ui/Row";
import { Col } from "@/client/components/ui/Col";
import { Button } from "@/client/components/ui/Button";
import { Image } from "@/client/components/ui/Image";

// Module
import st from "./styles.module.css";

export type StandardErrorReporterProps = {
  image?: { src: string; alt: string };
  title: ReactNode;
  description?: ReactNode;
  btnLabel: string;
  onReset: () => void;
};

export const StandardErrorReporter = ({
  image,
  title,
  description = null,
  btnLabel,
  onReset,
}: StandardErrorReporterProps) => {
  return (
    <Row
      justify="space-around"
      align="middle"
      gutter={Row.gutterType.md.horizontal}
      className={st["error-fallback"]}
      data-testid="error-fallback"
    >
      <Col
        xs={{ span: 24, order: 1 }}
        md={{ span: 12, order: 0 }}
        lg={{ span: 8, offset: 2, order: 0 }}
      >
        <div className={st["error-message"]}>{title}</div>

        {description && <div className={st.description}>{description}</div>}
        <Button block size="large" type="primary" onClick={onReset}>
          {btnLabel}
        </Button>
      </Col>
      {image && (
        <Col
          xs={{ span: 24, order: 0 }}
          md={{ span: 12, order: 1 }}
          lg={{ span: 8, offset: 2, order: 0 }}
        >
          <div className={st.illustration}>
            <Image src={image.src} alt={image.alt} fill sizes="" priority />
          </div>
        </Col>
      )}
    </Row>
  );
};
