"use client";

// Core
import { useTranslation } from "react-i18next";
import { useRouter } from "next/navigation";
import { usePathname } from "next/navigation";

// Components
import { Text } from "@/client/components/ui/Typography/Text";
import { Title } from "@/client/components/ui/Typography/Title";
import { StandardErrorReporter } from "@/client/components/page-elements/errors/standard-error-reporter";

// Utils
import { routes } from "@/client/definitions/book";

export const PageNotFound = () => {
  const { t } = useTranslation(["common", "buttons"]);
  const router = useRouter();
  const pathname = usePathname();

  const handleReset = () => {
    return pathname === routes.notFound ? router.push(routes.home) : router.refresh();
  };

  const notFoundTitle = (
    <Title tag="h1" size="36" font="second" fontWeight="800">
      {t("common:pages.notFound.oops")}
      <br />
      {t("common:pages.notFound.title")}
    </Title>
  );
  const notFoundDescription = (
    <Text tag="p" color="gray-900" size="14">
      {t("common:pages.notFound.description")}
    </Text>
  );

  return (
    <StandardErrorReporter
      image={{ src: "/assets/images/error-404.svg", alt: t("common:pages.notFound.title") }}
      onReset={handleReset}
      title={notFoundTitle}
      description={notFoundDescription}
      btnLabel={t("buttons:tryAgain")}
    />
  );
};
